import React from 'react';
import Hero from './Hero';
import { Breakpoint, Box } from '@mui/material';
import { height } from '../ResponsiveAppBar';

const DownArrow = () => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='52px'
    height='52px'
    viewBox='493.444 271.64 52 52'
    enableBackground='new 493.444 271.64 52 52'
  >
    <g>
      <path
        fill='#EFF8FC'
        d='M519.444,323.64c-14.337,0-26-11.663-26-26c0-14.336,11.663-26,26-26s26,11.664,26,26
		C545.444,311.977,533.781,323.64,519.444,323.64z M519.444,273.64c-13.233,0-24,10.767-24,24s10.767,24,24,24s24-10.767,24-24
		S532.677,273.64,519.444,273.64z'
      />
      <path
        fill='#EFF8FC'
        d='M519.445,305.269c-0.266,0-0.52-0.105-0.707-0.293l-9.946-9.947c-0.391-0.391-0.391-1.023,0-1.414
		s1.023-0.391,1.414,0l9.239,9.24l9.237-9.24c0.391-0.391,1.023-0.391,1.414,0c0.391,0.39,0.391,1.023,0,1.414l-9.944,9.947
		C519.964,305.164,519.71,305.269,519.445,305.269z'
      />
    </g>
  </svg>
);

export const MoreHeightUnits = 6;

interface FullHeightHeroProps {
  image: React.ReactNode;
  maxWidth?: Breakpoint;
  scrollAnchor: string;
}

export default (props: React.PropsWithChildren<FullHeightHeroProps>) => (
  <Hero image={props.image} maxWidth={props.maxWidth} minHeight={`calc(100vh - ${height})`}>
    <Box
      // Zmiana punktu odniesienia dla position: "absolute".
      position='relative'
      // Przerwa na zmieszczenie strzałki między children a krawędzią.
      paddingBottom={MoreHeightUnits}
    >
      {props.children}
    </Box>
    <Box
      // Wycentrowanie zawartego elementu - strzałki do przewijania.
      display='flex'
      justifyContent='center'
      // Dosunięcie do dolnej krawędzi przodka z position: "relative".
      position='absolute'
      bottom={0}
      // Rozciągnięcie na całą szerokość zamiast width: "100%".
      // https://stackoverflow.com/questions/49209970/width-100-of-an-absolute-positioned-element-is-bigger-than-the-parent
      left={0}
      right={0}
      // Odstęp strzałki od krawędzi - przeliczany przez theme.spacing.
      paddingBottom={2}
    >
      <a title='Zobacz więcej' href={'#' + props.scrollAnchor}>
        <DownArrow />
      </a>
    </Box>
  </Hero>
);
