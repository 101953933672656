import * as React from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import { Box, Stack, Typography } from '@mui/material';
import FullHeightHero, { MoreHeightUnits } from './FullHeightHero';
import { Link } from 'gatsby-material-ui-components';
import theme from '../../../theme';

const Checkmark = () => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='62px'
    height='62px'
    viewBox='0 0 62 62'
    enableBackground='new 0 0 62 62'
  >
    <g>
      <path
        fill='#ffffff'
        d='M28.137,42.587L28.137,42.587c-0.398,0-0.779-0.158-1.061-0.439L15.056,30.127
            c-0.586-0.586-0.586-1.536,0-2.121c0.586-0.586,1.535-0.586,2.121,0l10.96,10.96l19.114-19.114c0.586-0.586,1.535-0.586,2.121,0
            c0.586,0.585,0.586,1.536,0,2.121L29.197,42.148C28.916,42.429,28.535,42.587,28.137,42.587z'
      ></path>
      <path
        fill='#ffffff'
        d='M31,62C13.907,62,0,48.093,0,31S13.907,0,31,0s31,13.907,31,31S48.093,62,31,62z M31,2
            C15.009,2,2,15.009,2,31s13.009,29,29,29s29-13.009,29-29S46.991,2,31,2z'
      ></path>
    </g>
  </svg>
);

interface FunctionHeroPointProps {
  title: string;
}

const FunctionHeroPoint = (props: FunctionHeroPointProps) => (
  <Stack direction={{ xs: 'column', sm: 'row' }} my={{ xs: 3, sm: 4 }} spacing={2} alignItems='center'>
    <Checkmark />
    <Typography
      variant='h6'
      component='h3'
      color='white'
      maxWidth={{ xs: '100%', sm: '50%' }}
      textAlign={{ xs: 'center', sm: 'left' }}
      whiteSpace='pre-wrap'
    >
      {props.title}
    </Typography>
  </Stack>
);

interface FunctionHeroProps {
  backgroundImage: IGatsbyImageData;
  title: string;
  slug: string;
  points: readonly string[];
  image: IGatsbyImageData;
  scrollAnchor: string;
}

export default (props: FunctionHeroProps) => (
  <FullHeightHero
    maxWidth='md'
    scrollAnchor={props.scrollAnchor}
    image={<GatsbyImage style={{ height: '100%' }} image={props.backgroundImage} alt='' />}
  >
    <Link to={props.slug}>
      <Typography variant='h1' textTransform='uppercase' color='white' mb={{ xs: 3, sm: 6 }}>
        {props.title}
      </Typography>
    </Link>
    <Box mb={{ xs: 3, sm: 12 }}>
      {props.points.map((point, index) => (
        <FunctionHeroPoint key={index} title={point} />
      ))}
    </Box>
    <Box
      paddingX={{ xs: 2, sm: 'initial' }}
      paddingTop={{ xs: 0, sm: 'initial' }}
      paddingBottom={{ xs: MoreHeightUnits, sm: 'initial' }}
      position={{ xs: 'initial', sm: 'absolute' }}
      right={{ xs: 'initial', sm: 0 }}
      bottom={{ xs: 'initial', sm: theme.spacing(MoreHeightUnits) }}
    >
      <GatsbyImage image={props.image} alt='' />
    </Box>
  </FullHeightHero>
);
