import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import { ListItemButton } from 'gatsby-material-ui-components';
import Enumerable from 'linq';
import slugify from 'slugify';

import { Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';

import StandardFrame from '../../components/StandardFrame';
import FunctionHero from '../../components/Heros/FunctionHero';
import AppBarOffsetedScrollContainer from '../../components/AppBarOffsetedScrollContainer';
import ArticleStyledHtml from '../../components/ArticleStyledHtml';
import StandardBreadcrumbs from '../../components/StandardBreadcrumbs';
import PageSection from '../../components/Sections/PageSection';
import PageSectionTitle from '../../components/Sections/PageSectionTitle';
import DemoFormSectionContent from '../../components/Sections/DemoFormSectionContent';

export const pageQuery = graphql`
  query Function($id: String!) {
    current: markdownRemark(childFunction: { id: { eq: $id } }) {
      id
      fields {
        slug
      }
      frontmatter {
        category
        title
        hero_points
        hero_background {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 95, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        hero_image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 95, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      html
    }
    all: allMarkdownRemark(filter: { childFunction: { id: { ne: null } } }) {
      group(field: frontmatter___category) {
        fieldValue
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              order
              title
              card_icon {
                publicURL
              }
            }
          }
        }
      }
    }
    persona: allMarkdownRemark(
      filter: { childPersona: { id: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            demo_form
            demo_form_width
            demo_form_height
            order
          }
        }
      }
    }
  }
`;

export default ({
  data: {
    current,
    all: { group },
    persona
  }
}: PageProps<Queries.FunctionQuery>) => {
  // TODO: Może warto to uwspólnić pomiędzy stronami z listą i pełnym opisem.
  group = Enumerable.from(group)
    .orderBy(({ edges }) => Enumerable.from(edges).min(({ node }) => node.frontmatter!.order || 0))
    .toArray();

  return (
    <>
      <Helmet title={current!.frontmatter!.title!} />
      <StandardFrame>
        <FunctionHero
          backgroundImage={current!.frontmatter!.hero_background!.childImageSharp!.gatsbyImageData!}
          title={current!.frontmatter!.title!}
          slug={current!.fields!.slug!}
          points={current!.frontmatter!.hero_points!.map((p) => p!)}
          image={current!.frontmatter!.hero_image!.childImageSharp!.gatsbyImageData!}
          scrollAnchor='content'
        />
        <AppBarOffsetedScrollContainer id='content' maxWidth='lg'>
          <StandardBreadcrumbs
            mt={3}
            path={[
              { title: 'Funkcje', link: '/funkcje' },
              {
                title: current!.frontmatter!.category!,
                link: '/funkcje#' + slugify(current!.frontmatter!.category!, { lower: true })
              },
              { title: current!.frontmatter!.title!, link: current!.fields!.slug! }
            ]}
          />
          {/* Grid nie robi tutaj margin collapse z Typography, więc mt=3-2=1. */}
          <Grid container mt={1} rowSpacing={0} columnSpacing={8} direction='row-reverse'>
            <Grid item xs={12} md={8}>
              <ArticleStyledHtml html={current!.html!} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography mt={2} variant='h5' textTransform='uppercase' color='text.secondary'>
                Zobacz również w jaki sposób:
              </Typography>
              {group.map(({ fieldValue, edges }) => (
                <section key={fieldValue}>
                  <Typography mt={2} variant='h6' textTransform='uppercase'>
                    {fieldValue}
                  </Typography>
                  <List dense>
                    {edges.map(({ node }) => (
                      <ListItem key={node.id} disableGutters>
                        <ListItemButton to={node!.fields!.slug!} selected={current!.id === node!.id}>
                          <ListItemText
                            primaryTypographyProps={{
                              variant: 'caption',
                              textTransform: 'uppercase'
                            }}
                          >
                            {node!.frontmatter!.title!}
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <img src={node.frontmatter!.card_icon!.publicURL!} height='20' alt='' />
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </section>
              ))}
            </Grid>
          </Grid>
        </AppBarOffsetedScrollContainer>
        <PageSection maxWidth='lg' bgcolor='tertiary.main'>
          <PageSectionTitle>Sprawdź jak Mediporta może wyglądać u Ciebie</PageSectionTitle>
          <DemoFormSectionContent
            forms={persona.edges.map(({ node }) => ({
              title: node.frontmatter!.title!,
              src: node.frontmatter?.demo_form,
              width: node.frontmatter!.demo_form_width!,
              height: node.frontmatter!.demo_form_height!
            }))}
          />
        </PageSection>
      </StandardFrame>
    </>
  );
};
